<template>
  <div>
    <page-title :heading="$t('generic.lang_orders')" :icon="icon"
                :subheading="$t('generic.lang_orders')"></page-title>
    <div class="app-main__inner">
      <OrdersComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import OrdersComponent from "@/components/analytics/warewanlytics/orders/OrdersComponent";
export default {

  name: "index",
  components: {OrdersComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>